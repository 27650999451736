<template>
  <div>
    <!-- user total card -->
    <v-row class="mb-5">
      <!-- section -->
      <v-col cols="12" sm="6" md="3">
        <v-card @click="$router.push('/section')">
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ data.section_count }}
              </h2>
              <span>الاقسام</span>
            </div>

            <v-avatar color="#F9FBE7">
              <v-icon size="25" color="#827717" class="rounded-0">
                fab fa-buffer
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- service -->
      <v-col cols="12" sm="6" md="3">
        <v-card @click="$router.push('/allService')">
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ data.service_count }}
              </h2>
              <span>الخدمات</span>
            </div>
            <v-avatar color="#E8F5E9">
              <v-icon size="25" color="#1B5E20" class="rounded-0">
                fa-chart-bar
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- works -->
      <v-col cols="12" sm="6" md="3">
        <v-card @click="$router.push('/works')">
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ data.works_count }}
              </h2>
              <span>اخر الاعمال</span>
            </div>

            <v-avatar color="#E8F5E9">
              <v-icon size="25" color="#1B5E20" class="rounded-0">
                fa-spa
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- offers -->
      <v-col cols="12" sm="6" md="3">
        <v-card @click="$router.push('/offers')">
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ data.offers_count }}
              </h2>
              <span>العروض</span>
            </div>

            <v-avatar color="#FBE9E7">
              <v-icon size="25" color="#BF360C" class="rounded-0">
                fa-gift
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- customer -->
      <v-col cols="12" sm="6" md="3">
        <v-card @click="$router.push('/customer')">
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ data.customer_count }}
              </h2>
              <span>الزبائن</span>
            </div>

            <v-avatar color="#FAFAFA">
              <v-icon size="25" color="#212121" class="rounded-0">
                fa-user
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- customer_jop -->
      <v-col cols="12" sm="6" md="3">
        <v-card @click="$router.push('/customerJop')">
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ data.customer_jop_count }}
              </h2>
              <span>اعمال الزبون</span>
            </div>

            <v-avatar color="#E0F7FA">
              <v-icon size="25" color="#006064" class="rounded-0">
                fa-briefcase
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- notification -->
      <v-col cols="12" sm="6" md="3">
        <v-card @click="$router.push('/notification')">
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ data.notification_count }}
              </h2>
              <span>الاشعارات</span>
            </div>

            <v-avatar color="#E0F2F1">
              <v-icon size="25" color="#004D40" class="rounded-0">
                fa-bell
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- notification -->
      <v-col cols="12" sm="6" md="3">
        <v-card @click="$router.push('/customerBirthdayToday')">
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ data.customers_birthday_count }}
              </h2>
              <span>الزبائن الذين يصادف عيد ميلادهم اليوم</span>
            </div>

            <v-avatar color="#FFCCBC">
              <v-icon size="25" color="#004D40" class="rounded-0">
                fa-birthday-cake
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: {
        section_count: null,
        service_count: null,
        customer_count: null,
        customer_jop_count: null,
        customers_birthday_count: null,
        notification_count: null,
        offers_count: null,
        works_count: null,
      },
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.$http
        .get('dashboard')
        .then(Response => {
          if (Response.data.error === false) {
            this.data = Response.data.results
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
